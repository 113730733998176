import React from 'react';
import InputPassword from './passwordInput.component';
import { Button, Divider } from '@material-ui/core';
import { Form, Field } from 'formik';
const NewPasswordForm = props => {
    return (
        <Form>
            <div className="w-100 h-100">
                <div className="row no-gutters pb-4">
                    <Field
                        variant="outlined"
                        fullWidth
                        label="Contraseña nueva"
                        name="newPassword"
                        autoComplete="false"
                        component={InputPassword}
                    ></Field>
                </div>
                <div className="row no-gutters pb-4">
                    <Field
                        variant="outlined"
                        fullWidth
                        label="Confirmar nueva contraseña"
                        name="confirmNewPassword"
                        autoComplete="false"
                        component={InputPassword}
                    ></Field>
                </div>
                <Divider />
                <div className="row w-100 pt-2">
                    <div className="w-100">
                        <div className="float-right">
                            <Button type="submit">Aceptar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default NewPasswordForm;
