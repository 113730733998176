import React, { useState } from 'react';
import GroupsList from './groups-list.component';
import { Card, Tooltip, IconButton, Divider, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GroupsInfoWrapper from './groups-info';
import clsx from 'clsx';
import RemoveIcon from '@material-ui/icons/Remove';
import AlertDialog from '../../generic/alert/alertDialog.component';
import { formatCompleteUserName } from '../../../utils/user-utils';
import { Searcher } from '@snc/ui';

const useStyles = makeStyles(() => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
}));

const Groups = props => {
    const { groups, users } = props;

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [isNewGroup, setIsNewGroup] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const classes = useStyles();

    const disabledClassname = clsx({
        [classes.disabled]: !(selectedGroup || isNewGroup),
    });

    const handleSelectGroup = group => {
        const groupUsers = group.users.map(u => ({ ...u, ...users.find(us => us.userId === u.userId) }));
        if (selectedGroup === null || (selectedGroup !== null && group !== null && selectedGroup.usersGroupId !== group.usersGroupId)) {
            setSelectedGroup({ ...group, users: groupUsers });
            setIsNewGroup(false);
        }
    };
    const handleIsNew = () => {
        setSelectedGroup(null);
        setIsNewGroup(true);
    };
    const changeUsersGroup = (list, values) => {
        if (selectedGroup) {
            setSelectedGroup({ ...selectedGroup, users: list });
        } else {
            setSelectedGroup({ ...values, users: list });
        }
    };
    const handleDisableGroup = () => {
        props.disableGroup(selectedGroup);
        setShowConfirmation(false);
    };
    const handleSaveGroup = values => {
        if (!isNewGroup) {
            props.putGroup({ ...selectedGroup, ...values }, selectedGroup.users);
        } else {
            if (selectedGroup) props.postGroup({ ...selectedGroup, ...values }, selectedGroup.users);
            else props.postGroup(values, []);
        }
        setSelectedGroup(null);
    };
    return (
        <Card className="w-100 h-100 d-flex flex-column">
            <CardHeader
                title={'Grupos'}
                action={
                    <>
                        {selectedGroup && groups && groups.length > 0 && groups.filter(g => g.active).length > 1 ? (
                            <Tooltip title={'Desactivar grupo'}>
                                <IconButton onClick={() => setShowConfirmation(true)}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                        <Tooltip title={'Agregar nuevo Grupo'}>
                            <IconButton onClick={() => handleIsNew()}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        <AlertDialog
                            title={'Confirmar desactivar grupo'}
                            text={'Desactivar un grupo provoca que no vuelva a ser posible utilizarlo. ¿Desea continuar?'}
                            onAcceptClick={handleDisableGroup}
                            open={showConfirmation}
                            close={() => setShowConfirmation(false)}
                        />
                    </>
                }
            />
            <Divider />
            <CardContent className="h-100 w-100 row no-gutters flex-grow-1 overflow-auto">
                <div className="col-3 pl-2 pr-2 h-100" style={{ borderRight: '1px solid lightgrey' }}>
                    <Searcher value={searchText} onChange={setSearchText} />
                    <div className="w-100 overflow-auto" style={{ height: '90%' }}>
                        <GroupsList
                            showCount
                            selectedGroup={selectedGroup}
                            setSelectedGroup={group => handleSelectGroup(group)}
                            groups={
                                searchText && searchText !== ''
                                    ? groups.filter(g => g.usersGroupName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                                    : groups
                            }
                        />
                    </div>
                </div>
                <div className={`col-9 w-100 h-100 p-3 d-flex flex-column ${disabledClassname}`}>
                    <GroupsInfoWrapper
                        newGroup={isNewGroup}
                        users={users}
                        groupUsers={
                            selectedGroup
                                ? selectedGroup.users
                                      .sort((a, b) => formatCompleteUserName(a, true).localeCompare(formatCompleteUserName(b, true)))
                                      .filter(u => u.active)
                                : []
                        }
                        changeUsersGroup={changeUsersGroup}
                        selectedGroup={selectedGroup}
                        saveGroup={handleSaveGroup}
                        setIsNewGroup={setIsNewGroup}
                    />
                </div>
            </CardContent>
        </Card>
    );
};
export default Groups;
