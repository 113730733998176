import { Card, CardContent, CardHeader, Divider, Grid, Tooltip, IconButton } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import * as authTypes from '../../../model/authTypes';
import InputPassword from '../../generic/passwordChange/passwordInput.component';
import CheckIcon from '@material-ui/icons/Check';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { i18n } from '../../../i18n';

const mapStateToProps = state => {
    return {
        loggedUser: state.users.loggedUser,
        config: state.config.config,
    };
};

const ChangeMyPassword = ({ loggedUser, config }) => {
    //Get authentication method of the user
    const { authenticationMethods } = loggedUser;
    let auth = authTypes.NONE;
    if (authenticationMethods !== null && authenticationMethods.length > 0) {
        if (authenticationMethods.find(am => am.type === authTypes.LDAP)) auth = authTypes.LDAP;
        if (authenticationMethods.find(am => am.type === authTypes.LOCAL)) auth = authTypes.LOCAL;
    }

    return (
        <Card className="w-100 h-100 d-flex flex-column">
            <CardHeader title={i18n.changePassword} />
            <Divider />
            <CardContent>
                {auth === authTypes.LOCAL ? (
                    <ChangeMyPasswordForm changeMyPasswordURL={`${config.AUTH.BASE}/change_my_password`} userId={loggedUser.userId} />
                ) : (
                    i18n.changePasswordErrorAuthType
                )}
            </CardContent>
        </Card>
    );
};

export default connect(mapStateToProps, null)(ChangeMyPassword);

const ChangeMyPasswordForm = ({ changeMyPasswordURL, userId }) => {
    const { enqueueSnackbar } = useSnackbar();

    const initialValues = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };

    const validationSchema = Yup.object({
        oldPassword: Yup.string().required(i18n.requiredField),
        newPassword: Yup.string().required(i18n.requiredField),
        confirmNewPassword: Yup.string()
            .required(i18n.requiredField)
            .oneOf([Yup.ref('newPassword'), null], i18n.passwordMatch),
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
                await axios
                    .post(changeMyPasswordURL, {
                        oldPassword: values.oldPassword,
                        newPassword: values.newPassword,
                        userId,
                    })
                    .then(response => enqueueSnackbar(i18n.changePasswordSuccess, { variant: 'success' }))
                    .catch(error => enqueueSnackbar(i18n.changePasswordError, { variant: 'error' }));
            }}
            validationSchema={validationSchema}
        >
            <Form>
                <ChangeMyPasswordFormLayout />
            </Form>
        </Formik>
    );
};

const ChangeMyPasswordFormLayout = () => {
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Field
                    variant="outlined"
                    fullWidth
                    label={i18n.oldPassword}
                    name="oldPassword"
                    autoComplete="false"
                    required
                    component={InputPassword}
                ></Field>
            </Grid>
            <Grid item>
                <Field
                    variant="outlined"
                    fullWidth
                    label={i18n.newPassword}
                    name="newPassword"
                    autoComplete="false"
                    required
                    component={InputPassword}
                ></Field>
            </Grid>
            <Grid item>
                <Field
                    variant="outlined"
                    fullWidth
                    label={i18n.confirmNewPassword}
                    name="confirmNewPassword"
                    autoComplete="false"
                    required
                    component={InputPassword}
                ></Field>
            </Grid>
            <Grid item container justify="flex-end">
                <Tooltip title={i18n.save}>
                    <IconButton type="submit">
                        <CheckIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};
