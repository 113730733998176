import React, { Component } from 'react';

import Roles from './roles.component';
import { fetchRoles, handleSaveRole } from '../../../redux/administration/roles/ActionCreators';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { setCheckedPermissions } from '../../../redux/administration/permissions/ActionCreators';
import { ActionProgressReport, ActionProgressReportType } from '@snc/ui';

const mapStateToProps = state => {
    return {
        roles: state.roles,
    };
};

const mapDispatchToProps = dispatch => ({
    getRoles: () => dispatch(fetchRoles()),
    setCheckedPermissions: checkedPermissions => dispatch(setCheckedPermissions(checkedPermissions)),
    handleSaveRole: role => dispatch(handleSaveRole(role)),
});
class RolesWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRole: null,
            isLoading: true,
        };
        this.setSelectedRole = this.setSelectedRole.bind(this);
        this.handleSaveRoleInfo = this.handleSaveRoleInfo.bind(this);
        this.handleNewRole = this.handleNewRole.bind(this);
    }

    componentDidMount() {
        this.props
            .getRoles()
            .then(() =>
                this.setState({
                    isLoading: false,
                })
            )
            .catch(() =>
                this.setState({
                    isLoading: false,
                })
            ); //FIXME did not had catch prior to adding isLoading, decide what to do
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.roles.isUpdating && !this.props.roles.isUpdating && !this.props.roles.updateErrMess) {
            this.props.enqueueSnackbar('Guardado correctamente', {
                variant: 'success',
            });
        } else if (prevProps.roles.isUpdating && !this.props.roles.isUpdating && this.props.roles.updateErrMess) {
            this.props.enqueueSnackbar(`${this.props.roles.updateErrMess}`, {
                variant: 'error',
            });
        }
        if (prevProps.roles.isCreating && !this.props.roles.isCreating && !this.props.roles.createErrMess) {
            this.props.enqueueSnackbar('Guardado correctamente', {
                variant: 'success',
            });
        } else if (prevProps.roles.isCreating && !this.props.roles.isCreating && this.props.roles.createErrMess) {
            this.props.enqueueSnackbar(`${this.props.roles.createErrMess}`, {
                variant: 'error',
            });
        }
    }

    setSelectedRole(role) {
        this.setState({ selectedRole: role });
        if (role) this.props.setCheckedPermissions(role.permissions);
    }

    handleSaveRoleInfo(roleInfo, newRole) {
        this.props.handleSaveRole(roleInfo);
        this.setState({ selectedRole: null });
    }

    handleNewRole() {
        this.props.setCheckedPermissions([]);
    }

    render() {
        const { roles } = this.props;
        const { isCreating, isUpdating } = roles;

        const { isLoading } = this.state;

        return (
            <div className="row no-gutters w-100 h-100">
                {isLoading && <ActionProgressReport />}
                {isCreating ? <ActionProgressReport action={ActionProgressReportType.CREATING} /> : null}
                {isUpdating ? <ActionProgressReport action={ActionProgressReportType.UPDATING} /> : null}
                <Roles
                    roles={roles.roles}
                    selectedRole={this.state.selectedRole}
                    setSelectedRole={this.setSelectedRole}
                    handleSaveRole={this.handleSaveRoleInfo}
                    newRole={this.handleNewRole}
                />
            </div>
        );
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(RolesWrapper));
