import React, { useState, useEffect } from 'react';
import { Typography, MenuItem, Button } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import LdapBrowser from '../../generic/ldap/ldap-browser';
import * as authTypes from '../../../model/authTypes';
import ChangePasswordDialog from '../../generic/passwordChange/change-password.component';
import { i18n } from '../../../i18n';

const UserAuthForm = props => {
    const { newUser } = props;

    const AuthTypes_List = [
        { id: 0, value: authTypes.NONE, text: i18n.none },
        { id: 1, value: authTypes.LOCAL, text: authTypes.LOCAL },
        { id: 2, value: authTypes.LDAP, text: authTypes.LDAP },
    ];
    const [isNewPassword, setIsNewPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedLdapUser, setSelectedLdapUser] = useState(null);

    useEffect(() => {
        if (newUser) setIsNewPassword(true);
    }, [newUser]);

    const handleClose = value => {
        setOpen(false);
        if (value) {
            setSelectedLdapUser(value);
            props.setFieldValue('dn', value.distinguishedName);
        }
    };
    const handleChangePasswordClose = () => {
        setOpen(false);
    };
    const handleOnSubmit = values => {
        const { oldPassword, newPassword } = values;
        props.setFieldValue('newPassword', newPassword);
        props.setFieldValue('oldPassword', oldPassword);
        setOpen(false);
    };

    const onAuthTypeChange = event => {
        const selectedAuthType = AuthTypes_List.find(at => at.value === event.target.value);
        if (selectedAuthType && selectedAuthType.value === authTypes.LOCAL) {
            setIsNewPassword(true);
        }
    };

    return (
        <div className="row no-gutters p-1">
            <div className="row no-gutters w-100 mb-4">
                <Typography variant="h4" gutterBottom>
                    Datos de autenticación
                </Typography>
            </div>
            <div className="row no-gutters w-100">
                <div className="row no-gutters w-100">
                    <div className="col-12 col-sm-4 col-md-4 col-xl-4 p-1">
                        <Field name="username" required type="text" variant="outlined" fullWidth label="Usuario" component={TextField} />
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-xl-4 p-1">
                        <Field
                            name="authType"
                            required
                            type="text"
                            variant="outlined"
                            fullWidth
                            label="Autenticación"
                            select
                            component={TextField}
                            inputProps={{ onChange: onAuthTypeChange }}
                        >
                            {AuthTypes_List.map(authType => (
                                <MenuItem key={authType.id} value={authType.value}>
                                    {authType.text}
                                </MenuItem>
                            ))}
                        </Field>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-xl-4 p-1">
                        {props.values.authType === authTypes.LOCAL ? (
                            <div className="w-100 h-100">
                                <Button variant="outlined" onClick={() => setOpen(true)} fullWidth style={{ height: 56 }}>
                                    {isNewPassword ? 'Establecer contraseña' : 'Cambiar contraseña'}
                                </Button>
                                <ChangePasswordDialog
                                    open={open}
                                    isNew={isNewPassword}
                                    onClose={handleChangePasswordClose}
                                    onSubmit={handleOnSubmit}
                                />
                            </div>
                        ) : props.values.authType === authTypes.LDAP ? (
                            <div className="w-100 h-100">
                                <Button variant="outlined" onClick={() => setOpen(true)} fullWidth style={{ height: 56 }}>
                                    Vincular LDAP
                                </Button>
                                <LdapBrowser selectedValue={selectedLdapUser} open={open} onClose={handleClose} />
                                <Field name="dn" type="hidden" />
                            </div>
                        ) : null}
                    </div>

                    {props.values.dn ? (
                        <div className="row no-gutters w-100 pt-2">
                            <span>
                                <strong>Vinculado a:</strong>
                            </span>
                            <div className="row no-gutters w-100">
                                {props.values.dn.split(',').map((val, index) => {
                                    return (
                                        <div key={index} className="col-12 col-sm-12 col-md-3 col-xl-2 p-1">
                                            <span>{val}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default UserAuthForm;
