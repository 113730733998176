import React from 'react';
import { Formik } from 'formik';
import UserDetailsForm from './users-details-form';
import * as authTypes from '../../../model/authTypes';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { i18n } from '../../../i18n';

const UserInfoWrapper = props => {
    const { enqueueSnackbar } = useSnackbar();
    return (
        <Formik
            initialValues={{
                username: '',
                authType: authTypes.NONE,
                newPassword: '',
                oldPassword: '',
                dn: '',
                name: '',
                firstSurname: '',
                secondSurname: '',
                phoneNumber: '',
                mobilePhoneNumber: '',
                email: '',
                image: '',
                imageType: '',
                externalCode: '',
            }}
            validationSchema={Yup.object({
                username: Yup.string().required('Obligatorio'),
                authType: Yup.string().oneOf(Object.keys(authTypes)).required('Obligatorio'),
                password: Yup.string(),
                dn: Yup.string(),
                name: Yup.string().required('Obligatorio'),
                firstSurname: Yup.string(),
                secondSurname: Yup.string(),
                phoneNumber: Yup.string(),
                mobilePhoneNumber: Yup.string(),
                email: Yup.string(),
                image: Yup.string(),
                imageType: Yup.string(),
                externalCode: Yup.string().nullable(),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                let userInfo;
                if (!props.newUser) {
                    userInfo = { ...props.selectedUser };
                }
                userInfo = {
                    ...userInfo,
                    ...values,
                    allowedLogin: values.authType !== 0,
                    usersGroupId: 1,
                };
                const shouldNewPasswordExist =
                    (props.newUser && (userInfo.authType !== authTypes.LDAP || userInfo.authType !== authTypes.NONE)) ||
                    (userInfo.authenticationMethods
                        ? !userInfo.authenticationMethods.some(am => am.type === authTypes.LOCAL) && userInfo.authType === authTypes.LOCAL
                        : false);
                const ldapError = userInfo.authType === authTypes.LDAP && userInfo.dn === '';
                if ((shouldNewPasswordExist && userInfo.newPassword === '') || ldapError)
                    enqueueSnackbar(i18n.passwordRequired, { variant: 'error' });
                else {
                    props.handleSaveUser(userInfo, props.newUser);
                    resetForm();
                }
            }}
            component={componentProps => <UserDetailsForm {...componentProps} {...props} />}
        />
    );
};

export default UserInfoWrapper;
