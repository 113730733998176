import React from 'react';
import { Card, CardHeader, Divider, CardContent } from '@material-ui/core';
const BasicCard = props => {
    const { title, children } = props;
    return (
        <Card className="w-100 h-100 d-flex flex-column">
            <CardHeader title={title} />
            <Divider />
            <CardContent className="h-100 w-100 flex-grow-1 overflow-auto">{children}</CardContent>
        </Card>
    );
};

export default BasicCard;
