import React, { Component } from 'react';
import './App.scss';
import 'typeface-roboto';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import { withCookies } from 'react-cookie';

import Main from './components/Main';
import { assureToken, isTokenValid } from '@snc/api';
import { ActionProgressReport } from '@snc/ui';

import { fetchConfig } from './redux/config/ActionCreators';
import { userLoggedIn } from './redux/administration/users/ActionCreators';

// SNC CSS Files
import './theme-variables.scss';
import './css/snc-mui-tables.scss';
import './css/snc-styled-list.scss';
import './css/snc-mui-controls.scss';
import './css/snc-layout.scss';
import './css/snc-text.scss';
import './css/snc-utils.scss';

import { getUser } from '@snc/app-bar';

const mapStateToProps = state => {
    return {
        config: state.config,
        users: state.users,
    };
};
const mapDispatchToProps = dispatch => ({
    fetchConfig: () => dispatch(fetchConfig()),
    setUser: loggedUser => dispatch(userLoggedIn(loggedUser)),
});

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        this.props
            .fetchConfig()
            .then(config => {
                if (config && config.THEME) {
                    const theme = config.THEME;

                    const html = document.getElementsByTagName('html')[0];
                    Object.keys(theme).forEach((property, i) => {
                        if (property === 'name') {
                            return;
                        }
                        html.style.setProperty('--' + property, theme[property]);
                    });
                }

                const { cookies } = this.props;

                if (!isTokenValid(cookies.get('token'))) window.location = this.props.config.config.LOGIN_URL;
                else {
                    assureToken(() => cookies.get('token'), this.redirectToLogin);

                    this.setState({ isLoading: false }, () => {
                        getUser()
                            .then(user => {
                                this.props.setUser(user);
                            })
                            .catch(() => {
                                // No need to do anything because the info is shown by @snc/app-bar
                            });
                    });
                }
            })
            .catch(e => {
                this.setState(() => {
                    throw new Error(e);
                });
            });
    }

    redirectToLogin = () => {
        this.props.cookies.remove('token', { path: '/' });
        let redirectUri = this.props.config.config.LOGIN_URL || process.env.REACT_APP_LOGIN_URL;
        redirectUri += `?redirect=${window.location}`;
        window.location.replace(redirectUri);
    };

    render() {
        if (this.state.isLoading || this.props.config.isLoading) return <ActionProgressReport />;
        return (
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <SnackbarProvider
                        maxSnack={1}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div className="App">
                            <Main />
                        </div>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </BrowserRouter>
        );
    }
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(App));
