import React from 'react';

import { ButtonBase, Button, Divider } from '@material-ui/core';

import './Home.scss';

function Home(props) {
    // const [favourites, setFavourites] = useState(null);

    const { links } = props;

    // const fetchFavourite = useCallback(() => {
    //     getRequest('http://192.168.2.2:9020/launchers/1/favorites/67')
    //         .then(favourites => setFavourites(favourites))
    //         .catch(() => {
    //             //TODO show error? user may not have defined a favourite
    //         });
    // }, []);

    // useEffect(() => {
    //     fetchFavourite();
    // }, [fetchFavourite]);

    // const favorite = favourites && favourites.link ? favourites.link.linkUrl : undefined;

    // if (favorite && !props.noRedirect) window.location.assign(favorite);

    const apps = [...links].filter(link => !link.isExternal);
    const externalLinks = [...links].filter(link => link.isExternal);

    return (
        <div className="h-100 limited-width-1200 pt-4 pl-3 pr-3 pb-3 d-flex flex-column">
            <div className="w-100 pt-2 mb-4 flex-grow-1">
                <div className="row">
                    {apps.map(link => (
                        <div key={link.id} className="col-12 col-sm-6 col-md-4 col-xl-3 pt-4">
                            <AppContent link={link} />
                        </div>
                    ))}
                </div>
            </div>

            {externalLinks && externalLinks.length > 0 && (
                <div className="w-100">
                    <div className="row">
                        <div className="col-12">
                            <Divider />
                        </div>
                    </div>

                    <div className="row pb-4 external-links-container">
                        {externalLinks.map(link => {
                            return (
                                <div key={link.id} className="col-6 col-sm-4 col-md-3 col-xl-2 pt-4">
                                    <AppContent link={link} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

function AppContent(props) {
    const { linkUrl, active, isExternal, image, sublinks } = props.link;

    return (
        <div className="w-100">
            <div className="w-100">
                <ButtonBase className="w-100 emi-launcher-app-entry-btn" disabled={!active}>
                    <a className="w-100" href={linkUrl} target={isExternal ? '_blank' : ''}>
                        <div className="emi-launcher-app-entry w-100">
                            <svg width="100%" height="100%" viewBox="0 0 900 450" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <rect id="rounded-image-rect" x="0" y="0" width="900" height="500" />
                                    <clipPath id="rounded-image-clip">
                                        <use href="#rounded-image-rect" />
                                    </clipPath>
                                </defs>

                                <image
                                    href={image ? `data:image/${image.imageType};base64,${image.content}` : ''}
                                    x="50"
                                    y={isExternal ? '25' : '165'}
                                    width="800"
                                    height={isExternal ? '400' : '125'}
                                    preserveAspectRatio="xMidYMid meet"
                                    clipPath="url(#rounded-image-clip)"
                                    style={{ boxShadow: '0 0 10px', opacity: 0.86, padding: '20px' }}
                                />
                            </svg>
                        </div>
                    </a>
                </ButtonBase>
            </div>

            {[...sublinks]
                .sort((a, b) => a.position - b.position)
                .map(({ id, active, name, sublinkUrl, color }) => (
                    <div className="w-100 pt-2 emi-launcher-app-entry-sublink" key={id}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                window.location = sublinkUrl;
                            }}
                            style={{
                                backgroundColor: 'white',
                                width: '100%',
                                textAlign: 'left',
                                border: 'none',
                                borderLeft: `6px ${color} solid`,
                            }}
                            disabled={!active}
                        >
                            <span className="w-100 mb-0">{name}</span>
                        </Button>
                    </div>
                ))}
        </div>
    );
}

export default Home;
