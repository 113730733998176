import React from 'react';
import { ListItem, List, ListItemText, ListItemAvatar, Avatar, Typography } from '@material-ui/core';
import { formatCompleteUserName } from '../../../utils/user-utils';
import LdapLinked from '../../generic/ldap/ldap-linked.component';

const UsersList = props => {
    const { users, setSelectedUser, selectedUser } = props;
    return users ? (
        <List>
            {users.map((user, index) => {
                return (
                    <ListItem
                        selected={selectedUser && selectedUser.userId === user.userId}
                        disabled={!user.active}
                        onClick={() => setSelectedUser(user)}
                        key={index}
                        dense
                        button
                    >
                        <ListItemAvatar>
                            {user.image && user.imageType ? (
                                <Avatar src={`data:${user.imageType};base64,${user.image}`} />
                            ) : (
                                <Avatar src={`${process.env.PUBLIC_URL}/assets/USUARIO.svg`} />
                            )}
                        </ListItemAvatar>
                        <ListItemText
                            primary={formatCompleteUserName(user, true)}
                            secondary={
                                <div className="row no-gutters align-items-center">
                                    <Typography className="mr-2"> {user.username} </Typography>
                                    {user.authenticationMethods &&
                                    user.authenticationMethods.length > 0 &&
                                    user.authenticationMethods.some(am => am.type === 'LDAP') ? (
                                        <LdapLinked />
                                    ) : null}
                                </div>
                            }
                        />
                    </ListItem>
                );
            })}
        </List>
    ) : null;
};

export default UsersList;
