import React, { Component } from 'react';
import UserAuthForm from './user-auth-form';
import { Divider, IconButton, Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { Form } from 'formik';
import UserInfoForm from './user-info-form';
import * as authTypes from '../../../model/authTypes';

class UserDetailsForm extends Component {
    constructor(props) {
        super(props);
        if (props.selectedUser && !props.newUser) {
            props.setFieldValue('username', props.selectedUser.username ?? '');
            const { authenticationMethods } = props.selectedUser;

            if (authenticationMethods !== null && authenticationMethods.length > 0) {
                let auth = authTypes.NONE;
                if (authenticationMethods.find(am => am.type === authTypes.LDAP)) auth = authTypes.LDAP;
                if (authenticationMethods.find(am => am.type === authTypes.LOCAL)) auth = authTypes.LOCAL;
                props.setFieldValue('authType', auth);
            }

            props.setFieldValue(
                'dn',
                props.selectedUser.authenticationMethods !== null && props.selectedUser.authenticationMethods.length > 0
                    ? props.selectedUser.authenticationMethods[0].ldapUsername ?? ''
                    : ''
            );
            props.setFieldValue('name', props.selectedUser.name ?? '');
            props.setFieldValue('firstSurname', props.selectedUser.firstSurname ?? '');
            props.setFieldValue('secondSurname', props.selectedUser.secondSurname ?? '');
            props.setFieldValue('phoneNumber', props.selectedUser.phoneNumber ?? '');
            props.setFieldValue('mobilePhoneNumber', props.selectedUser.mobilePhoneNumber ?? '');
            props.setFieldValue('email', props.selectedUser.email ?? '');
            props.setFieldValue('image', props.selectedUser.image ?? '');
            props.setFieldValue('imageType', props.selectedUser.imageType ?? '');
            props.setFieldValue('externalCode', props.selectedUser.externalCode ?? '');
        }
        if (props.newUser) {
            props.resetForm();
        }
    }
    render() {
        return (
            <Form className="w-100 h-100 d-flex flex-column" noValidate>
                <UserAuthForm {...this.props} />
                <div className="mb-5" />
                <Divider />
                <div className="mb-3" />
                <div className="flex-grow-1">
                    <UserInfoForm {...this.props} />
                </div>
                <div className="row no-gutters w-100">
                    <div className="w-100">
                        <div className="float-right">
                            <Tooltip title={'Guardar datos'}>
                                <IconButton type="submit">
                                    <CheckIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default UserDetailsForm;
