import React, { Component } from 'react';

import { fetchUsers } from '../../../redux/administration/users/ActionCreators';
import { connect } from 'react-redux';
import Assignment from './assignment.component';
import { fetchUsersFromGroup } from '../../../redux/administration/groups/ActionCreators';
import { setAssignedRoles, fetchRoles } from '../../../redux/administration/roles/ActionCreators';
import { putUser } from '../../../redux/administration/users/ActionCreators';
import { ActionProgressReport, ActionProgressReportType } from '@snc/ui';

const mapStateToProps = state => {
    return {
        roles: state.roles,
        users: state.users,
    };
};

const mapDispatchToProps = dispatch => ({
    getUsers: () => dispatch(fetchUsers()),
    getRoles: () => dispatch(fetchRoles()),
    getUserGroups: groupId => dispatch(fetchUsersFromGroup(groupId)),
    setAssignedRoles: roles => dispatch(setAssignedRoles(roles)),
    putUser: user => dispatch(putUser(user)),
});

let timer = null;

class AssignmentWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: null,
            selectedGroup: null,
            isLoading: true,
        };

        this.setSelectedUser = this.setSelectedUser.bind(this);
        this.setSelectedGroup = this.setSelectedGroup.bind(this);
        this.setCheckedRoles = this.setCheckedRoles.bind(this);
        this.updateUser = this.updateUser.bind(this);
    }

    componentDidMount() {
        Promise.all([this.props.getUsers(), this.props.getRoles()])
            .then(() =>
                this.setState({
                    isLoading: false,
                })
            )
            .catch(() =>
                this.setState({
                    isLoading: false,
                })
            ); //FIXME did not had catch prior to adding isLoading, decide what to do
    }

    setSelectedUser(user) {
        this.setState({ selectedUser: user, selectedGroup: null });
    }

    setSelectedGroup(group) {
        this.setState({ selectedGroup: group });
        this.setCheckedRoles(group.roles, true);
    }

    setCheckedRoles(check, noUpdate) {
        clearTimeout(timer);
        if (Array.isArray(check)) {
            this.props.setAssignedRoles(check);
            return;
        }

        let checked = [...this.props.roles.assignedRoles];
        const exist = checked.find(c => c.roleId === check.roleId);
        if (exist) {
            checked = checked.filter(c => c.roleId !== check.roleId);
        } else {
            checked = [...checked, check];
        }
        this.props.setAssignedRoles(checked);

        if (!noUpdate) {
            timer = setTimeout(this.updateUser, 250);
        }
    }

    updateUser() {
        const newGroup = { ...this.state.selectedGroup };
        if (newGroup && newGroup.roles) {
            newGroup.roles = { ...this.state.selectedGroup.roles };
            newGroup.roles = this.props.roles.assignedRoles;
            this.props
                .putUser({
                    ...this.state.selectedUser,
                    groups: [
                        ...this.state.selectedUser.groups.map(item => {
                            if (item.usersGroupId !== this.state.selectedGroup.usersGroupId) {
                                return item;
                            }
                            return {
                                ...item,
                                ...newGroup,
                            };
                        }),
                    ],
                })
                .then(a => {
                    if (this.state.selectedUser) {
                        this.setState({ selectedUser: this.props.users.users.find(u => u.userId === this.state.selectedUser.userId) });
                    }
                });
        }
    }

    render() {
        const { isUpdating } = this.props.users;

        const { isLoading } = this.state;
        return (
            <div className="row no-gutters w-100 h-100">
                {isLoading && <ActionProgressReport />}
                {isUpdating ? <ActionProgressReport action={ActionProgressReportType.UPDATING} /> : null}
                <Assignment
                    selectedUser={this.state.selectedUser}
                    setSelectedUser={this.setSelectedUser}
                    selectedGroup={this.state.selectedGroup}
                    setSelectedGroup={this.setSelectedGroup}
                    setCheckedRoles={this.setCheckedRoles}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentWrapper);
