import React from 'react';
import { ListItem, List, ListItemText } from '@material-ui/core';

const RolesList = props => {
    const { roles, setSelectedRole, selectedRole } = props;
    return roles ? (
        <List>
            {roles.map((role, index) => {
                return (
                    <ListItem
                        selected={selectedRole && selectedRole.roleId === role.roleId}
                        disabled={!role.active}
                        onClick={() => setSelectedRole(role)}
                        key={index}
                        dense
                        button
                    >
                        <ListItemText primary={role.roleName} />
                    </ListItem>
                );
            })}
        </List>
    ) : null;
};

export default RolesList;
