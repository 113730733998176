import LocalizedStrings from 'react-localization';

export const i18n = new LocalizedStrings({
    es: {
        title: 'Launcher',
        menu: 'Menú',

        appLoadError: 'No ha sido posible cargar la aplicación',
        contactAdmin: 'Contacte con su administrador',
        none: 'NINGUNA',
        cancel: 'Cancelar',
        accept: 'Aceptar',

        chooseAnOption: 'Seleccione una opción desde el menú',

        // Admin menu
        administration: 'Administración',
        access: 'Acceso',
        users: 'Usuarios',
        groups: 'Grupos',
        roles: 'Roles',
        permissions: 'Permisos',
        passwordRequired: 'Ingrese una contraseña o asigne usuario de LDAP',

        administrationWelcome: 'Bienvenido al área de administración',

        //#region LINK
        links: 'Enlaces externos',
        name: 'Nombre',
        url: 'Url',
        position: 'Posición',
        description: 'Descripción',
        //#endregion

        //#region FORMIK
        requiredField: 'Campo Requerido',
        stringMax255: 'El valor debe contener 255 caracteres o menos',
        numberType: 'El valor debe ser númerico',
        integerType: 'El valor debe ser entero',
        positiveNumberType: 'El valor ha de ser un número positivo',
        stringType: 'El valor ha de ser un texto',
        dateType: 'El valor ha de ser una fecha',
        datetimeType: 'El valor ha de ser una fecha-hora',
        timeType: 'El valor ha de ser una hora',
        durationType: 'El valor debe contener un número de horas y minutos',
        dateRangeError: 'La Fecha Hasta debe ser posterior o igual a la Fecha Desde',
        //#endregion FIN FORMIK

        clear: 'Limpiar',
        create: 'Crear',
        edit: 'Editar',

        addLink: 'Añadir enlace',
        deleteLink: 'Borrar enlace',
        addExternal: 'Agregar enlace externo',
        editExternal: 'Editar enlace externo',

        save: 'Guardar',
        applications: 'Mis aplicaciones',
        external: 'Enlaces externos',

        assignment: 'Asignación',

        profile: 'Perfil',
        logout: 'Desconectar',

        unauthorized: 'No dispone de permisos para visualizar esta sección',

        myProfile: 'Mi perfil',
        changePassword: 'Cambiar contraseña',
        changePasswordSuccess: 'Tu contraseña ha sido cambiada correctamente',
        changePasswordError: 'Ha habido un error, inténtalo de nuevo',
        changePasswordErrorAuthType: 'No es posible cambiar la contraseña porque el tipo de autorizacion no es local.',
        oldPassword: 'Contraseña antigua',
        newPassword: 'Contraseña nueva',
        confirmNewPassword: 'Confirmar nueva contraseña',
        passwordMatch: 'Las contraseñas deben coincidir',
    },
    en: {
        title: 'Launcher',
        menu: 'Menu',

        appLoadError: "Can't load the app",
        contactAdmin: 'Get in contact with your administrador',

        none: 'NONE',
        cancel: 'Cancel',
        accept: 'Accept',

        chooseAnOption: 'Choose an option from the menu',

        // Admin menu
        administration: 'Administration',
        access: 'Access',
        users: 'Users',
        groups: 'Groups',
        roles: 'Roles',
        permissions: 'Permissions',
        passwordRequired: 'Set a password or set the linked LDAP user',

        administrationWelcome: 'Welcome to the administration area',

        //#region LINK
        links: 'External links',
        name: 'Name',
        url: 'Url',
        position: 'Posición',
        description: 'Descripción',
        //#endregion

        //#region FORMIK
        requiredField: 'Required Field',
        stringMax255: 'Value must have 255 or less characters',
        numberType: 'Value must be a number',
        integerType: 'Value must be an integer',
        positiveNumberType: 'Value must be a positive number',
        stringType: 'Value must be a text',
        dateType: 'Value must be a date',
        datetimeType: 'Value must be a datetime',
        timeType: 'Value must be a time',
        durationType: 'Value must contain an amount of hours and minutes',
        dateRangeError: 'Until Date must be bigger or equal to From Date',
        ////#endregion FIN FORMIK

        clear: 'Clear',
        create: 'Create',
        edit: 'Edit',

        addLink: 'Add Link',
        deleteLink: 'Delete Link',
        addExternal: 'Add external link',
        editExternal: 'Edit extenal link',

        save: 'Save',
        applications: 'My applications',
        external: 'External links',

        assignment: 'Assignment',

        profile: 'Profile',
        logout: 'Logout',

        unauthorized: 'Not enought permissions to display this section',

        myProfile: 'My profile',
        changePassword: 'Change password',
        changePasswordSuccess: 'Your password has been changed',
        changePasswordError: "There's been an error, try again",
        changePasswordErrorAuthType: 'Cannot change password because auth type is not local.',
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password',
        passwordMatch: 'Passwords must match',
    },
});
