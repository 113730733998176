import React, { Component } from 'react';

import { fetchUsers, putUser, registerUser } from '../../../redux/administration/users/ActionCreators';
import { connect } from 'react-redux';
import { fetchGroups } from '../../../redux/administration/groups/ActionCreators';
import Users from './users';
import { withSnackbar } from 'notistack';
import { withCookies } from 'react-cookie';
import { ActionProgressReport, ActionProgressReportType } from '@snc/ui';

const mapStateToProps = state => {
    return {
        users: state.users,
        groups: state.groups,
    };
};
const mapDispatchToProps = dispatch => ({
    getUsers: () => {
        return dispatch(fetchUsers());
    },
    getGroups: () => {
        return dispatch(fetchGroups());
    },
    putUser: user => dispatch(putUser(user)),
    registerUser: user => dispatch(registerUser(user)),
});

class UsersGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: null,
            isLoading: true,
        };
        this.setSelectedUser = this.setSelectedUser.bind(this);
        this.handleSaveUser = this.handleSaveUser.bind(this);
    }

    componentDidMount() {
        Promise.all([this.props.getUsers(), this.props.getGroups()])
            .then(() =>
                this.setState({
                    isLoading: false,
                })
            )
            .catch(() =>
                this.setState({
                    isLoading: false,
                })
            ); //FIXME did not had catch prior to adding isLoading, decide what to do
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.users.isUpdating && !this.props.users.isUpdating && !this.props.users.updateErrMess) {
            this.props.enqueueSnackbar('Guardado correctamente', {
                variant: 'success',
            });
        } else if (prevProps.users.isUpdating && !this.props.users.isUpdating && this.props.users.updateErrMess) {
            this.props.enqueueSnackbar(`${this.props.users.updateErrMess}`, {
                variant: 'error',
            });
        }
        if (prevProps.users.isCreating && !this.props.users.isCreating && !this.props.users.createErrMess) {
            this.props.enqueueSnackbar('Guardado correctamente', {
                variant: 'success',
            });
        } else if (prevProps.users.isCreating && !this.props.users.isCreating && this.props.users.createErrMess) {
            this.props.enqueueSnackbar(`${this.props.users.createErrMess}`, {
                variant: 'error',
            });
        }
    }

    setSelectedUser(user) {
        this.setState({ selectedUser: user });
    }

    handleSaveUser(userInfo, newUser) {
        if (newUser) {
            this.props.registerUser(userInfo).then(usr => this.setSelectedUser(usr));
        } else {
            this.props.putUser(userInfo);
            this.setSelectedUser(null);
        }
    }

    render() {
        const { users } = this.props;
        const { isCreating, isUpdating } = users;
        const { isLoading } = this.state;

        return (
            <div className="row no-gutters w-100 h-100">
                {isLoading && <ActionProgressReport />}
                {isCreating ? <ActionProgressReport action={ActionProgressReportType.CREATING} /> : null}
                {isUpdating ? <ActionProgressReport action={ActionProgressReportType.UPDATING} /> : null}
                <Users
                    users={users.users.filter(u => u.active)}
                    selectedUser={this.state.selectedUser}
                    setSelectedUser={this.setSelectedUser}
                    handleSaveUser={this.handleSaveUser}
                />
            </div>
        );
    }
}

export default withCookies(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(UsersGroups)));
