import React from 'react';
import { Dialog, Divider, DialogContent } from '@material-ui/core';
import CustomDialogTitle from '../modal-title/modal-title.component';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ChangePasswordForm from './passwordForm.component';
import NewPasswordForm from './newPassword-form.component';
const ChangePasswordDialog = props => {
    const initialValuesChange = {
        // oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };

    const validationSchemaChange = Yup.object({
        // oldPassword: Yup.string().required('Old password is required'),
        newPassword: Yup.string().required('New password is required'),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    });

    const initialValuesNew = {
        newPassword: '',
        confirmNewPassword: '',
    };

    const validationSchemaNew = Yup.object({
        newPassword: Yup.string().required('New password is required'),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    });

    const { open, onClose, onSubmit, isNew } = props;
    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'} scroll={'paper'} disableBackdropClick>
            <CustomDialogTitle handleCloseDialog={onClose}>Cambiar contraseña</CustomDialogTitle>
            <Divider />
            <DialogContent>
                <div className="p-2">
                    {isNew ? (
                        <Formik
                            initialValues={initialValuesNew}
                            validationSchema={validationSchemaNew}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setSubmitting(false);
                                onSubmit(values);
                                resetForm();
                            }}
                            component={componentProps => <NewPasswordForm {...componentProps} {...props} />}
                        ></Formik>
                    ) : (
                        <Formik
                            initialValues={initialValuesChange}
                            validationSchema={validationSchemaChange}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setSubmitting(false);
                                onSubmit(values);
                                resetForm();
                            }}
                            component={componentProps => <ChangePasswordForm {...componentProps} {...props} />}
                        ></Formik>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ChangePasswordDialog;
