import React, { useState } from 'react';
import { Card, CardHeader, Tooltip, IconButton, Divider, CardContent, makeStyles } from '@material-ui/core';
import { Remove, Add } from '@material-ui/icons';
import AlertDialog from '../../generic/alert/alertDialog.component';
import RolesList from './roles-list.component';
import clsx from 'clsx';
import RolesInfoWrapper from './roles-info-wrapper.component';
import { Searcher } from '@snc/ui';

const useStyles = makeStyles(() => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
}));

const Roles = props => {
    const [isNewRole, setIsNewRole] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [roleSearchText, setRoleSearchText] = useState('');
    const { roles, handleSaveRole, selectedRole, setSelectedRole, newRole } = props;

    const classes = useStyles();
    const disabledClassname = clsx({
        [classes.disabled]: !(selectedRole || isNewRole),
    });

    const deactivateRole = () => {
        handleSaveRole({ ...selectedRole, active: false });
        setShowConfirmation(false);
    };

    const handleIsNewRole = () => {
        setIsNewRole(true);
        newRole();
    };

    return (
        <Card className="w-100 h-100 d-flex flex-column">
            <CardHeader
                title="Roles"
                action={
                    <>
                        {selectedRole && roles && roles.length > 0 && roles.filter(g => g.active).length > 1 ? (
                            <Tooltip title={'Desactivar rol'}>
                                <IconButton onClick={() => setShowConfirmation(true)}>
                                    <Remove />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                        <Tooltip title={'Agregar nuevo rol'}>
                            <IconButton onClick={() => handleIsNewRole(true)}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                        <AlertDialog
                            title={'Confirmar desactivar rol'}
                            text={'Desactivar un rol provoca que no vuelva a ser posible utilizarlo. ¿Desea continuar?'}
                            onAcceptClick={deactivateRole}
                            open={showConfirmation}
                            close={() => setShowConfirmation(false)}
                        />
                    </>
                }
            />
            <Divider />
            <CardContent className="h-100 w-100 row no-gutters flex-grow-1 overflow-auto">
                <div className="col-3 pl-2 pr-2 h-100" style={{ borderRight: '1px solid lightgrey' }}>
                    <Searcher value={roleSearchText} onChange={setRoleSearchText} />
                    <div className="w-100 overflow-auto" style={{ height: '90%' }}>
                        <RolesList
                            selectedRole={selectedRole}
                            setSelectedRole={role => {
                                setSelectedRole(role);
                                setIsNewRole(false);
                            }}
                            roles={
                                roleSearchText && roleSearchText !== ''
                                    ? roles.filter(r => r.roleName.toLowerCase().indexOf(roleSearchText.toLowerCase()) >= 0)
                                    : roles
                            }
                        />
                    </div>
                </div>
                <div className={`col-9 w-100 h-100 p-3 d-flex flex-column ${disabledClassname}`}>
                    <RolesInfoWrapper
                        newRole={isNewRole}
                        selectedRole={selectedRole}
                        handleSaveRole={roleInfo => {
                            handleSaveRole(roleInfo);
                            setIsNewRole(false);
                        }}
                    />
                </div>
            </CardContent>
        </Card>
    );
};

export default Roles;
