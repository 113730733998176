export const Routes = {
    home: '/',
    links: '/links',

    administration: '/administration',

    administrationUsers: '/administration/access/users',
    administrationGroups: '/administration/access/groups',
    administrationRoles: '/administration/access/roles',
    administrationAssigment: '/administration/access/assigment',

    administrationProfileChangeMyPassword: '/administration/profile/changeMyPassword',
};

export const RouteParams = {};
