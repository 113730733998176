import React from 'react';

import { AppDrawer } from '@snc/router-ui';

import { Routes } from '../../utils/routes';

import { i18n } from '../../i18n';

import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import LockIcon from '@material-ui/icons/Lock';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import UsersWrapper from './users/users-wrapper.component';
import GroupsWrapper from './groups/groups-wrapper.component';
import RolesWrapper from './roles/roles-wrapper.component';
import AssignmentWrapper from './assignment/assignment-wrapper.component';

import ChangeMyPassword from './profile/changeMyPassword';

import './Administration.scss';

import { connect } from 'react-redux';
import { canViewMenuItem } from '../generic/permissions/permission-utils';
import { MODULES } from '../../utils/module-utils';

const mapStateToProps = state => {
    return {
        loggedUser: state.users.loggedUser,
        config: state.config.config,
        users: state.users,
        apps: state.apps,
        roles: state.roles.roles,
        permissions: state.permissions.permissions,
    };
};

const getList = () => [
    {
        name: i18n.access,
        icon: <LockOpenIcon />,
        module: MODULES.AdministrationAccessSection.name,
        perm: MODULES.AdministrationAccessSection.perms.view,
        children: [
            {
                name: i18n.users,
                icon: <PersonIcon />,
                uri: Routes.administrationUsers,
                component: () => <UsersWrapper />,
            },
            {
                name: i18n.groups,
                icon: <GroupIcon />,
                uri: Routes.administrationGroups,
                component: () => <GroupsWrapper />,
            },
            {
                name: i18n.roles,
                icon: <LockIcon />,
                uri: Routes.administrationRoles,
                component: () => <RolesWrapper />,
            },
            {
                name: i18n.assignment,
                icon: <SettingsInputComponentIcon />,
                uri: Routes.administrationAssigment,
                component: () => <AssignmentWrapper />,
            },
        ],
    },
    {
        name: i18n.myProfile,
        icon: <PersonOutlineIcon />,
        children: [
            {
                name: i18n.changePassword,
                icon: <VpnKeyIcon />,
                uri: Routes.administrationProfileChangeMyPassword,
                component: () => <ChangeMyPassword />,
            },
        ],
    },
];

const Admninistration = props => {
    const { loggedUser, roles, permissions, config } = props;

    const { groups } = loggedUser || {};
    const { modules } = props.apps;
    const listItems = getList().filter(canViewMenuItem(groups || [], modules, roles, permissions, config.launcher.app_id));

    return (
        <div className="row no-gutters h-100 snc-emi-administration-area p-3">
            <AppDrawer items={listItems} defaultAreaFirstText={i18n.administrationWelcome} defaultAreaSecondText={i18n.chooseAnOption} />
        </div>
    );
};

export default connect(mapStateToProps, null)(Admninistration);
