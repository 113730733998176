import React from 'react';
import { List, ListItem, ListItemText, ListSubheader, ListItemIcon, Checkbox } from '@material-ui/core';
const PermissionsList = props => {
    const { permissions, checked, setChecked } = props;
    return (
        <>
            <ListSubheader className="mt-3">Permisos</ListSubheader>
            <div className="row no-gutters w-100 overflow-auto" style={{ height: '75%' }}>
                <List className="w-100">
                    {permissions.map((permission, index) => (
                        <ListItem button dense key={index} onClick={() => setChecked(permission)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checked.find(p => p.permissionId === permission.permissionId) ? true : false}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={permission.displayName} />
                        </ListItem>
                    ))}
                </List>
            </div>
        </>
    );
};

export default PermissionsList;
