import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    Divider,
    CardContent,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import UsersAssignableToGroup from './users-assignable-to-group';
import { Searcher } from '@snc/ui';
import { filterUsers } from '../../../utils/user-utils';

const GroupsDetailsList = props => {
    const { groupUsers, users, newGroup, changeUsersGroup, values } = props;
    const [openModal, setOpenModal] = useState(false);
    const [newToAssign, setNewToAssign] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchTextDialog, setSearchTextDialog] = useState('');

    const unassignUser = () => {
        const selectedIds = selectedUsers.map(us => us.userId);
        changeUsersGroup(groupUsers.filter(usr => selectedIds.indexOf(usr?.userId) === -1));
        setSelectedUsers([]);
    };

    const assignUser = () => {
        setOpenModal(true);
    };
    const onCloseModal = () => {
        setOpenModal(false);
        changeUsersGroup([...groupUsers, ...newToAssign], values);
        setSelectedUsers([]);
    };
    let assignableUsers = [];
    if (!newGroup) {
        const userIndexes = groupUsers.map(u => u?.userId);
        assignableUsers = users.filter(usr => userIndexes.indexOf(usr?.userId) < 0 && usr.active);
    }

    return (
        <div className="h-100 w-100 pr-1">
            <Card className="w-100 h-100 d-flex flex-column">
                <CardHeader
                    title="Usuarios Asignados"
                    action={
                        <>
                            {selectedUsers.length > 0 ? (
                                <Tooltip title={'Desasignar usuario'}>
                                    <IconButton onClick={() => unassignUser()}>
                                        <RemoveIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            <Tooltip title={'Asignar usuario'}>
                                <IconButton onClick={() => assignUser()}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                ></CardHeader>
                <Divider />
                <CardContent className="h-100 w-100 row no-gutters flex-grow-1 overflow-hidden">
                    <Searcher value={searchText} onChange={setSearchText} />
                    <div className="w-100 overflow-auto" style={{ height: '85%' }}>
                        <UsersAssignableToGroup
                            groupUsers={filterUsers(groupUsers, searchText)}
                            checked={selectedUsers}
                            setChecked={setSelectedUsers}
                        />
                    </div>
                </CardContent>
            </Card>
            <Dialog
                open={openModal}
                onClose={onCloseModal}
                className="h-100 d-flex flex-column"
                fullWidth={true}
                maxWidth={'sm'}
                scroll={'paper'}
                disableBackdropClick
            >
                <DialogTitle>Asignar Usuarios</DialogTitle>
                <DialogContent className="h-100 w-100 d-flex flex-column overflow-hidden">
                    {assignableUsers && assignableUsers.length > 0 ? (
                        <>
                            <div className="row no-gutters">
                                <Searcher value={searchTextDialog} onChange={txt => setSearchTextDialog(txt)} />
                            </div>

                            <div className="row no-gutters w-100 overflow-auto" style={{ height: '85%' }}>
                                <UsersAssignableToGroup
                                    groupUsers={filterUsers(assignableUsers, searchTextDialog)}
                                    checked={newToAssign}
                                    setChecked={setNewToAssign}
                                />
                            </div>
                        </>
                    ) : (
                        <span>No hay usuarios en el sistema que no estén en este grupo</span>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseModal}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default GroupsDetailsList;
