import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
import { ConfigureStore } from './redux/configureStore';
import { Provider } from 'react-redux';
import { ErrorBoundary } from './components/error/ErrorBoundary';

import './index.scss';

const store = ConfigureStore();

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </Provider>
    </CookiesProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
