import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Checkbox } from '@material-ui/core';
const RolesList = props => {
    const { roles, checked, setChecked } = props;
    return (
        <div className="row no-gutters w-100" style={{ height: '75%' }}>
            <List className="w-100 overflow-auto">
                {roles.map((role, index) => (
                    <ListItem button dense key={index} onClick={() => setChecked(role)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checked.find(p => p.roleId === role.roleId) ? true : false}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText primary={role.roleName} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default RolesList;
