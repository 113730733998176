import React from 'react';
import { ListItemIcon, Checkbox, ListItemText, List, ListItem } from '@material-ui/core';
import { formatCompleteUserName } from '../../../utils/user-utils';
const UsersAssignableToGroup = props => {
    const { groupUsers, checked, setChecked } = props;

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <List className="w-100">
            {groupUsers.map((usr, index) => {
                return (
                    <ListItem dense button key={index} onClick={handleToggle(usr)}>
                        <ListItemIcon>
                            <Checkbox edge="start" checked={checked.indexOf(usr) !== -1} tabIndex={-1} disableRipple />
                        </ListItemIcon>
                        <ListItemText primary={formatCompleteUserName(usr, true)}></ListItemText>
                    </ListItem>
                );
            })}
        </List>
    );
};

export default UsersAssignableToGroup;
