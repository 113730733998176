import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './Header';

import { fetchAppsAndVTModulesAction } from '../redux/administration/apps/ActionCreators';

import Administration from './administration/Administration';

import Home from './Home';
import { ActionProgressReportBg } from '@snc/ui';

import * as QueryString from 'query-string';

import { fetchLauncher } from '../redux/link/ActionCreators';
import { fetchPermissions } from '../redux/administration/permissions/ActionCreators';
import { fetchRoles } from '../redux/administration/roles/ActionCreators';
import { fetchGroups } from '../redux/administration/groups/ActionCreators';

import { Routes } from '../utils/routes';
import ErrorMessage from './generic/errormessage/ErrorMessage';
import { i18n } from '../i18n';

const mapStateToProps = state => {
    return {
        apps: state.apps,
        config: state.config.config,
        users: state.users,
        links: state.links,

        isLoadingUser: state.users.isLoading,
    };
};

const mapDispatchToProps = dispatch => ({
    fetchAppsAndModules: () => {
        return dispatch(fetchAppsAndVTModulesAction);
    },
    fetchLauncher: () => {
        return dispatch(fetchLauncher());
    },
    fetchPermissions: () => dispatch(fetchPermissions()),
    fetchRoles: () => dispatch(fetchRoles()),
    fetchGroups: () => dispatch(fetchGroups()),
});

function Main(props) {
    const {
        config,
        fetchAppsAndModules,
        fetchPermissions,
        fetchRoles,
        fetchGroups,
        fetchLauncher,
        isLoadingUser,
        isLoadingPermissions,
        isLoadingApps,
        isLoadingRoles,
        isLoadingGroups,
        links,
    } = props;

    // const [noRedirect, setNoRedirect] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [errMess, setErrMess] = useState(null);

    useEffect(() => {
        Promise.all([fetchAppsAndModules(), fetchLauncher(), fetchPermissions(), fetchRoles(), fetchGroups()])
            .then(() => setLoading(false))
            .catch(error => {
                setLoading(false);
                setErrMess(error.message);
            });
        //TODO loading and errors
    }, [fetchAppsAndModules, fetchLauncher, fetchPermissions, fetchRoles, fetchGroups]);

    if (isLoading || isLoadingPermissions || isLoadingApps || isLoadingRoles || isLoadingGroups) return <ActionProgressReportBg />;
    else if (errMess) return <ErrorMessage errorMessages={[i18n.appLoadError, i18n.contactAdmin]} errorCode={'404'} />;
    else
        return (
            <div className="w-100 h-100 d-flex flex-column">
                <div className="d-block">
                    <Header />
                </div>
                <div className="container-fluid pl-0 pr-0 flex-grow-1 overflow-auto">
                    {isLoadingUser ? (
                        <ActionProgressReportBg />
                    ) : (
                        <Switch>
                            <Route path={Routes.administration} render={props => <Administration />} />

                            <Route
                                exact
                                path={Routes.home}
                                render={props => {
                                    const params = QueryString.parse(props.location.search);

                                    return <Home config={config} links={links.links} noRedirect={params.noRedirect} />;
                                }}
                            />

                            <Redirect to="/" />
                        </Switch>
                    )}
                </div>
            </div>
        );
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
