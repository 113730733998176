import React from 'react';
import { Formik } from 'formik';
import GroupsDetailsForm from './groups-details-form';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
const useStyles = makeStyles(() => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
}));
const GroupsInfoWrapper = props => {
    const classes = useStyles();
    const disabledClassname = clsx({
        [classes.disabled]: !(!props.selectedGroup || (props.selectedGroup !== null && props.selectedGroup.active)),
    });
    return (
        <Formik
            initialValues={{
                usersGroupName: '',
                description: '',
                image: '',
                imageType: '',
            }}
            validationSchema={Yup.object({
                usersGroupName: Yup.string().required('Obligatorio'),
                description: Yup.string(),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                resetForm();
                props.saveGroup(values);
            }}
            component={componentProps => <GroupsDetailsForm {...componentProps} {...props} className={disabledClassname} />}
        />
    );
};

export default GroupsInfoWrapper;
