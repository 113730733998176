import React, { useState } from 'react';
import { Card, IconButton, Divider, CardContent, CardHeader, Tooltip, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import UserInfoWrapper from './usrs-details';
import UsersList from './users-list';

import clsx from 'clsx';
import AlertDialog from '../../generic/alert/alertDialog.component';
import { Searcher } from '@snc/ui';
import { filterUsers } from '../../../utils/user-utils';

const useStyles = makeStyles(() => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
    cardHeaderContent: {
        margin: 0,
    },
}));

const Users = props => {
    const [isNewUser, setIsNewUser] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [searchText, setSearchText] = useState('');

    const { users, handleSaveUser, selectedUser, setSelectedUser } = props;

    const classes = useStyles();

    const disabledClassname = clsx({
        [classes.disabled]: !(selectedUser || isNewUser),
    });

    const deactivateUser = () => {
        handleSaveUser({ ...selectedUser, active: false });
        setShowConfirmation(false);
    };
    return (
        <div className="row no-gutters w-100 h-100">
            <Card className="w-100 h-100 d-flex flex-column">
                <CardHeader
                    classes={{ action: classes.cardHeaderContent }}
                    title={'Usuarios'}
                    action={
                        <>
                            {selectedUser && users && users.length > 0 && users.filter(g => g.active).length > 1 ? (
                                <Tooltip title={'Desactivar usuario'}>
                                    <IconButton onClick={() => setShowConfirmation(true)}>
                                        <RemoveIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            <Tooltip title={'Agregar nuevo usuario'}>
                                <IconButton onClick={() => setIsNewUser(true)}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                            <AlertDialog
                                title={'Confirmar desactivar usuario'}
                                text={'Desactivar un usuario provoca que no vuelva a ser posible utilizarlo. ¿Desea continuar?'}
                                onAcceptClick={deactivateUser}
                                open={showConfirmation}
                                close={() => setShowConfirmation(false)}
                            />
                        </>
                    }
                />
                <Divider />
                <CardContent className="h-100 w-100 row no-gutters flex-grow-1 overflow-auto">
                    <div className="col-3 pl-2 pr-2 h-100 d-flex flex-column" style={{ borderRight: '1px solid lightgrey' }}>
                        <Searcher value={searchText} onChange={setSearchText} />
                        <div className="w-100" style={{ height: 300, flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                            <UsersList
                                selectedUser={selectedUser}
                                setSelectedUser={user => {
                                    setSelectedUser(user);
                                    setIsNewUser(false);
                                }}
                                users={filterUsers(users, searchText)}
                            />
                        </div>
                    </div>
                    <div className={`col-9 w-100 h-100 p-3 d-flex flex-column ${disabledClassname}`}>
                        <UserInfoWrapper newUser={isNewUser} selectedUser={selectedUser} handleSaveUser={handleSaveUser} />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default Users;
