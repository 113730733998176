import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ModulesList from './modules-list.component';
import PermissionsList from './permissions-list.component';
import { fetchModulesAction } from '../../../redux/administration/apps/ActionCreators';
import { connect } from 'react-redux';
import { setCheckedPermissions } from '../../../redux/administration/permissions/ActionCreators';

const mapDispatchToProps = dispatch => ({
    loadModules: appId => dispatch(fetchModulesAction(appId)),
    setCheckedPermissions: checked => dispatch(setCheckedPermissions(checked)),
});

const RolesManagement = props => {
    const { apps, modules } = useSelector(state => state.apps);
    const { checkedPermissions } = useSelector(state => state.permissions);
    const firstApp = apps && apps.length > 0 ? apps[0].applicationId : -1;
    const [selectedApp, setSelectedApp] = useState(firstApp);
    const [selectedModule, setSelectedModule] = useState(null);

    useEffect(() => {
        props.loadModules(selectedApp);
    }, [props, selectedApp]);

    const handleSetChecked = check => {
        let submittingCheckbox = [...checkedPermissions];
        const found = checkedPermissions.find(cp => cp.permissionId === check.permissionId);
        if (found) {
            submittingCheckbox = submittingCheckbox.filter(c => c.permissionId !== check.permissionId);
        } else {
            submittingCheckbox = [...submittingCheckbox, check];
        }
        props.setCheckedPermissions(submittingCheckbox);
    };
    return (
        <div className="row no-gutters p-1" style={{ height: '65%' }}>
            <div className="row no-gutters w-100">
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Aplicación</InputLabel>
                    <Select value={selectedApp} labelWidth={80} fullWidth onChange={event => setSelectedApp(event.target.value)}>
                        {apps.map((app, index) => {
                            return (
                                <MenuItem key={index} value={app.applicationId}>
                                    {app.displayName}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className="row no-gutters h-100 w-100">
                <div className="col-12 col-md-6 h-100">
                    <ModulesList
                        modules={Object.values(modules).filter(m => m.applicationId === selectedApp)}
                        selectedModule={selectedModule}
                        setSelectedModule={setSelectedModule}
                    />
                </div>
                {selectedModule ? (
                    <div className="col-12 col-md-6 h-100">
                        <PermissionsList
                            permissions={selectedModule.permissions}
                            checked={checkedPermissions}
                            setChecked={handleSetChecked}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default connect(null, mapDispatchToProps)(RolesManagement);
